/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.0/dist/jquery.min.js
 * - /npm/i18next@15.1.0/i18next.min.js
 * - /npm/i18next-browser-languagedetector@3.0.1/i18nextBrowserLanguageDetector.min.js
 * - /npm/i18next-xhr-backend@2.0.1/i18nextXHRBackend.min.js
 * - /npm/loc-i18next@0.1.4/loc-i18next.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
